import Header from '~/components/header/header'
import Account from '~/components/account/account'

export default function AccountPage() {
  return (
    <>
      <Header />
      <Account />
    </>
  )
}
