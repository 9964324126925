import { Component, For } from 'solid-js'
import styles from '~/components/account-preferred-role/account-preferred-role.module.scss'
import { editUser, user } from '~/services/database/users'
import { roles } from '~/translations/account-roles'
import DownIcon from '~/assets/icons/chevron-down.svg'
import { Role } from '~/services/database/permissions'

const AccountPreferredRole: Component = () => {
  let rolesRef: HTMLSelectElement | undefined

  const isSelected = (value: string) => {
    return value === user.latest?.preferred_role
  }

  const onChange = async () => {
    await editUser({
      preferred_role: rolesRef?.value
    })
  }

  return (
    <div class={styles.selectContainer}>
      <DownIcon class={styles.downArrow} />
      <select
        class={styles.select}
        ref={rolesRef}
        onChange={onChange}
      >
        <For each={roles}>
          {(entry) => (
            <option
              value={entry.value}
              selected={isSelected(entry.value)}
            >
              {new Role(entry.value).label}
            </option>
          )}
        </For>
      </select>
    </div>
  )
}

export default AccountPreferredRole
