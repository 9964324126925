import { Component, For } from 'solid-js'
import DownIcon from '~/assets/icons/chevron-down.svg'
import styles from '~/components/language-selection/language-selection.module.scss'
import { updateSettings } from '~/services/settings/settings'
import { language, languages, setLanguage } from '~/translations'
import { Language } from '~/translations/types'

const LanguageSelection: Component = () => {
  let languageRef: HTMLSelectElement | undefined

  const options = Object.entries(languages).map(([key, language]) => ({
    value: key,
    label: language.label
  }))

  const isSelected = (value: string) => {
    return value === language()
  }

  const onChange = async () => {
    const language = languageRef?.value as Language
    setLanguage(language)
    updateSettings({
      language
    })
    location.reload()
  }

  return (
    <div class={styles.selectContainer}>
      <DownIcon class={styles.downArrow} />
      <select
        class={styles.select}
        ref={languageRef}
        onChange={onChange}
      >
        <For each={options}>
          {(entry) => (
            <option
              value={entry.value}
              selected={isSelected(entry.value)}
            >
              {entry.label}
            </option>
          )}
        </For>
      </select>
    </div>
  )
}

export default LanguageSelection
