import { Component, For, createEffect, createResource, createSignal, untrack } from 'solid-js'
import { user } from '~/services/database/users'
import styles from '~/components/account/account.module.scss'
import Button from '~/components/button/button'
import { supabase } from '~/services/supabase'
import { useNavigate } from '@solidjs/router'
import { deleteContact, getContacts } from '~/services/database/contacts'
import RowButton from '~/components/row-button/row-button'
import AccountPreferredRole from '~/components/account-preferred-role/account-preferred-role'
import TogglePill from '~/components/toggle-pill/toggle-pill'
import { updateSettings, settings } from '~/services/settings/settings'
import DeleteIcon from '~/assets/icons/delete.svg'
import UserIcon from '~/assets/icons/user.svg'
import { PillOption } from '~/components/toggle-pill/toggle-pill.interfaces'
import LanguageSelection from '~/components/language-selection/language-selection'
import { translations } from '~/translations'

const Account: Component = () => {
  const navigate = useNavigate()
  const [contacts, { refetch: refetchContacts }] = createResource(getContacts)
  const [themes, setThemes] = createSignal<PillOption[]>([
    {
      label: translations().account.profile.theme.light,
      value: 'light',
      checked: !settings().darkmode
    },
    {
      label: translations().account.profile.theme.dark,
      value: 'dark',
      checked: settings().darkmode
    }
  ])

  const onLogout = () => {
    supabase.auth.signOut().finally(() => {
      navigate('/account/login')
    })
  }

  const refresh = () => {
    refetchContacts()
  }

  const onContactDelete = (contactId: string) => async () => {
    deleteContact({ contactId }).then(() => {
      refresh()
    })
  }

  createEffect(() => {
    const isDarkmode = themes().find(theme => theme.checked)?.value === 'dark'
    untrack(() => {
      updateSettings({
        darkmode: isDarkmode
      })
    })
  })
  
  return (
    <div class={styles.container}>
      <div class={styles.group}>
        <div class={styles.title}>
          {translations().account.profile.profile}
        </div>
        <div class={styles.profileData}>
          <div class={styles.entry}>
            <div class={styles.label}>
              {translations().account.profile.lastName}
            </div>
            <div class={styles.value}>
              {user.latest?.name ?? '-'}
            </div>
          </div>
          
          <div class={styles.entry}>
            <div class={styles.label}>
              {translations().account.profile.preferredRole}
            </div>
            <div class={styles.value}>
              <AccountPreferredRole />
            </div>
          </div>
          
          <div class={styles.entry}>
            <div class={styles.label}>
              {translations().account.profile.language}
            </div>
            <div class={styles.value}>
              <LanguageSelection />
            </div>
          </div>
          
          <div class={styles.entry}>
            <div class={styles.label}>
              {translations().account.profile.interface}
            </div>
            <div class={styles.value}>
              <TogglePill
                options={themes()}
                setOptions={setThemes}
              />
            </div>
          </div>
          
          <div class={styles.entry}>
            <Button onClick={onLogout}>
              {translations().account.profile.logout}
            </Button>
          </div>
        </div>
      </div>
      <div class={styles.group}>
        <div class={styles.title}>
          {translations().account.contacts.contacts}
        </div>
        <div class={styles.contactList}>
          <For each={contacts.latest}>
            {(contact) => (
              <div class={styles.entry}>
                <UserIcon class={styles.icon} />
                <div class={styles.name}>{contact.meta?.name}</div>
                <RowButton
                  icon={DeleteIcon}
                  label={translations().dashboard.contacts.savedContacts.remove}
                  onClick={onContactDelete(contact.contact_id)}
                />
              </div>
            )}
          </For>
        </div>
      </div>
    </div>
  )
}

export default Account
